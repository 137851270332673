/* Columns defination */
.col10{
    width: 100%;
    flex-direction: column;
}
.col9{
    width: 90%;
    flex-direction: column;
}
.col8{
    width: 80%;
    flex-direction: column;
}
.col7{
    width: 70%;
    flex-direction: column;
}
.col6{
    width: 60%;
    flex-direction: column;
}
.col5{
    width: 50%;
    flex-direction: column;
}
.col46{
    width: 46%;
    flex-direction: column;
}
.col4{
    width: 40%;
    flex-direction: column;
}
.col3{
    width: 30%;
    flex-direction: column;
}
.col25{
    width: 25%;
    flex-direction: column;
}
.col2{
    width: 20%;
    flex-direction: column;
}
.col1{
    width: 10%;
    flex-direction: column;
}
.col{
    flex-direction: column;
}

/* Color defination */
.errorMsg {
    color: #FF1F1F;
    font-family: Space Grotesk;
    font-size: 14px;
    font-weight: 400;
    line-height: 17.86px;
}
.bg-transparent{
    background-color: transparent;
}
.bg-black{
    background-color: black;
}
.bg-lightBlack{
    background-color: #000000cc;
}
.bg-section1-home{
    /*background: radial-gradient(circle at 100% 0%, #480048, black, black), radial-gradient(circle at 0% 100%, #480048, black, black), radial-gradient(circle, black, black), radial-gradient(circle at 0% 0%, #480048, black, black);*/
    background: radial-gradient(circle at 100% 0%, #480048, black, black), radial-gradient(at 0% 100%, #480048, black, black), radial-gradient(circle, black, black), radial-gradient(at 0% 0%, #480048, black, black);
    background-position: top right,top left, bottom right, bottom left;
    background-size: 51% 51%;
    background-repeat: no-repeat;
}
.bg-section1{
    /*background: radial-gradient(circle at 100% 0%, #480048, black, black), radial-gradient(circle at 0% 100%, #480048, black, black), radial-gradient(circle, black, black), radial-gradient(circle at 0% 0%, #480048, black, black);*/
    background: radial-gradient(circle at 100% -80%, #480048, black, black, black, black), radial-gradient(at 0% 100%, #480048, black, black);
    background-position: top right, bottom right;
    background-size: 100% 51%;
    background-repeat: no-repeat;
}
.bg-tx-black{
    background-color: black;
    color: #FFFFFF;
}

/* Utilities (display, margin, padding, visiblity) */
.h-100{
    height: 100vh;
}
.h-50{
    height: 50vh;
}
.h-40{
    height: 40vh;
}
.w-100{
    width: 100%;
}
.w-50{
    width: 50%;
}
.w-95{
    width: 95vw;
}
.w-90{
    width: 90vw;
}
.fixed{
    position: fixed;
}
.relative{
    position: relative;
}
.absolute{
    position: absolute;
}
.flex{
    display: flex !important;
}
.none{
    display: none;
}
.row{
    flex-direction: row;
}
.justify-content-between{
    justify-content: space-between
}
.justify-content-around{
    justify-content: space-around
}
.justify-content-center{
    justify-content: center
}
.justify-content-end{
    justify-content: end;
}
.align-items-center{
    align-items: center;
}
.center-center{
    display: flex !important;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.start-center{
    display: flex !important;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
}
.gap-40px{
    gap: 40px;
}
.gap-32px{
    gap: 32px;
}
.gap-24px{
    gap: 24px;
}
.gap-20px{
    gap: 20px;
}
.gap-16px{
    gap: 16px;
}
.of-hidden{
    overflow: hidden;
}
.m-100px{
    margin: 100px;
}
.m-0{
    margin: 0;
}
.mx-auto{
    margin-left: auto;
    margin-right: auto;
}
.mx-100px{
    margin-left: 150px;
    margin-right: 150px;
}
.mx-20px{
    margin-left: 20px;
    margin-right: 20px;
}
.my-30px{
    margin-top: 30px;
    margin-bottom: 30px;
}
.mt-10px{
    margin-top: 10px !important;
}
.mt-16px{
    margin-top: 16px !important;
}
.mt-24px{
    margin-top: 24px !important;
}
.mt-30px{
    margin-top: 30px;
}
.mt-40px{
    margin-top: 40px !important;
}
.mt-50px{
    margin-top: 50px;
}
.mt-100px{
    margin-top: 100px;
}
.mb-20px{
    margin-bottom: 20px !important;
}
.mb-30px{
    margin-bottom: 30px !important;
}
.mb-40px{
    margin-bottom: 40px !important;
}
.mb-50px{
    margin-bottom: 50px !important;
}
.mb-100px{
    margin-bottom: 100px;
}
.me-16px{
    margin-right: 16px;
}
.me-37px{
    margin-right: 37px;
}
.px-55px{
    padding-left: 58px !important;
    padding-right: 58px !important;
}
.px-30px{
    padding-left: 30px !important;
    padding-right: 30px !important;
}
.py-10px{
    padding-top: 10px;
    padding-bottom: 10px;
}
.py-20px{
    padding-top: 20px;
    padding-bottom: 20px;
}
.py-30px{
    padding-top: 30px;
    padding-bottom: 30px;
}
.py-40px{
    padding-top: 40px;
    padding-bottom: 40px;
}
.py-60px{
    padding-top: 60px;
    padding-bottom: 60px;
}
.py-100px{
    padding-top: 100px;
    padding-bottom: 100px;
}
.py-150px{
    padding-top: 150px;
    padding-bottom: 150px;
}
.py-250px{
    padding-top: 250px;
    padding-bottom: 250px;
}
.pt-20px{
    padding-top: 20px;
}
.pt-24px{
    padding-top: 24px;
}
.pt-40px{
    padding-top: 40px;
}
.pb-30px{
    padding-bottom: 30px;
}
.pb-40px{
    padding-bottom: 40px;
}
.pb-45px{
    padding-bottom: 45px;
}
.br-16px{
    border-radius: 16px;
}

/* Components */
.tag{
    background: linear-gradient(90deg, rgba(51, 51, 153, 0.3) 0%, rgba(255, 0, 204, 0.3) 100%);
    font-size: 18px;
    font-weight: 400;
    line-height: 18px;
    text-align: center;
    color: #FFFFFF;
    border-radius: 31px;
    padding: 8px 16px;
}
.page-title{
    margin: 0 150px;
    font-size: 62px;
    line-height: 62px;
    color: #FFFFFF;
}
.page-desc{
    margin: 10px 150px 0;
    color: #FFFFFF;
    font-size: 18px;
    line-height: 29px;
    font-weight: 400;
}
.heading{
    font-size: 52px;
    line-height: 52px;
    font-weight: 700;
    margin-bottom: 20px;
}
.sub-heading{
    color: #303030;
    font-size: 22px;
    line-height: 28.6px;
}
.card{
    background: #F5F5F5;
    padding: 40px;
    border-radius: 16px;
}
.card:hover{
    background: #FFFFFF;
    box-shadow: 8px 8px 34px 0px #00000017;
}
.card-contain{
    margin-top: 40px;
}
.card-icon svg{
    stroke: #000;
}
.card:hover .card-icon svg{
    stroke: url(#paint1_linear_35_194);
}
.card-title{
    font-size: 32px;
    font-weight: 700;
    line-height: 38.4px;
    margin: 0 0 32px;
}
.card-dark{
    border-radius: 16px;
    background: #111111;
    padding: 40px;
    height: 100%;
}
.card-dark .card-icon svg{
    stroke: #fff;
}
.card-dark:hover .card-icon svg{
    stroke: url(#paint1_linear_35_194);
}
button{
    background: linear-gradient(to right, #333399, #FF00CC);
    background-clip: padding-box;
    padding: 20px 42px 20px 42px;
    border-radius: 8px;
    color: #FFFFFF;
    font-size: 20px;
    border: 2px solid transparent;
}
button:hover{
    color: transparent;
    border: 2px double transparent;
    background-image: linear-gradient(to right, #333399, #FF00CC), linear-gradient(to right, #000, #000), linear-gradient(to right, #333399, #FF00CC);
    border-radius: 8px;
    background-origin: border-box;
    background-clip: text, padding-box, border-box;
}
.btnhover-light:hover{
    color: transparent;
    border: 2px double transparent;
    background-image: linear-gradient(to right, #333399, #FF00CC), linear-gradient(to right, #FFFFFF, #FFFFFF), linear-gradient(to right, #333399, #FF00CC);
    border-radius: 8px;
    background-origin: border-box;
    background-clip: text, padding-box, border-box;
}
.center{
    text-align: center;
}

/* Header */
.logo{
    height: 72px;
}
.logo-sm{
    height: 48px;
}
.menu{
    display: flex;
}
.menu-item{
    margin-left: 10px;
    margin-right: 10px;
}
.menu .menu-item{
    font-size: 16px;
    color: #FFFFFF80;
    text-decoration: none;
    padding-bottom: 10px;
}
.menu .menu-item.active{
    color: #FFFFFF;
    border-bottom: 1px solid;
}
a.menu-item{
    color: #FFFFFF;
}
button.menu-item{
    padding: 12px 22px 12px 22px;
    font-size: 16px;
}

/* Footer */
.footer{
    padding: 100px 100px 50px;
    border-bottom: 1px solid #1A1A1A;
}
.footer h6{
    font-size: 52px;
    font-weight: 700;
    line-height: 62.4px;
    margin: 0;
}
.footer-text{
    margin: 32px 0;
    font-size: 16px;
    font-weight: 400;
    line-height: 22.4px;
    opacity: 80%; 
}
.iconDiv{
    padding: 16px 16px 12px;
    border-radius: 50%;
    background: #1F1F1F;
}
.iconDiv:hover{
    background: linear-gradient(90deg, #333399 0%, #FF00CC 100%);
}
.footer-contact{
    opacity: 80%;
}
.footer-menu{
    font-size: 16px;
    font-weight: 400;
    line-height: 22.4px;
    text-align: left;
    opacity: 80%;
    color: #FFFFFF;
    text-decoration: none;
}
.copyrightTab{
    padding: 30px;
    font-family: Space Grotesk;
    font-size: 16px;
    font-weight: 400;
    line-height: 22.4px;
    opacity: 60%;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Page 404 */
.P404 h1{
    font-size: 200px;
    color: #FFFFFF;
    margin: initial;
}

/* Home Page */
.section1-desc{
    color: #FFFFFF;
    width: 845px;
    font-size: 22px;
    font-weight: 400;
    line-height: 35.2px;
    text-align: center;
    
}
.title{
    font-size: 76px;
    font-weight: 700;
    line-height: 100px;
    text-align: center;
    color: #FFFFFF;
    margin: initial;
}
.title div{
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
}
.title span:before {
    content: "Web Development"; 
    padding-left: 10px; 
    color: transparent;
    background: linear-gradient(90deg, #333399, #FF00CC);
    background-clip: text;
    animation: animate infinite 5s linear; 
}
.serviceName{
    font-size: 36px;
    font-weight: 400;
    line-height: 43.2px;
    margin: 0 0 10px 0
}
.card:hover .serviceName{
    color: transparent;
    background: linear-gradient(90deg, #333399, #FF00CC 40%);
    background-clip: text;
}
.serviceDescription{
    font-size: 16px;
    font-weight: 400;
    line-height: 22.4px;
    margin: 0;
}
.aboutLink{
    font-size: 24px;
    font-weight: 500;
    line-height: 24px;
    color: transparent;
    background: linear-gradient(90deg, #333399, #FF00CC 40%);
    background-clip: text;
    position:relative;
}
.aboutLink:before{
  content:"Know more about us >";
  position:absolute;
  color:transparent;
  transition:all 0.5s;
}
.aboutLink:hover:before{
  color: #FFFFFF;
}
.HWW{
    font-size: 52px;
    font-weight: 700;
    line-height: 52px;
    text-align: center;
    padding: 10px 35px;
}
.HWW-steps-circle{
    padding: 55.8px;
    border: 2px dashed #d3d3e5;
    border-radius: 50%;
    position: relative;
    z-index: -1;
}
.HWW-circle-out{
    border: 6.59px solid #ADABD6;
    border-radius: 50%;
}
.HWW-circle-in{
    border: 6.5px solid #EDF2FA;
    border-radius: 50%;
}
.dot {
  width: 24.7px;
  height: 24.7px;
  background-color: #ADABD6;
  border-radius: 50%;
  position: absolute;
}
.dot-1 {
  top: 14%;
  left: 85%;
}
.dot-2 {
  top: 50%;
  left: 97%;
}
.dot-3 {
  left: 79%;
}
.dot-5 {
  top: 50%;
  left: -3%;
}
.dot-6 {
  top: 14%;
  left: 9%;
}
.vector-right{
    position: absolute;
    top: -27px;
    right: -19px;
    z-index: -1;
}
.vector-left{
    position: absolute;
    top: -36px;
    right: -30px;
    z-index: -1;
}
.vector-1{
    transform: rotate(358deg);
}
.vector-2{
    transform: rotate(45deg);
}  
.vector-3{
    transform: rotate(92deg);
    top: -33px;
    right: -17px;
}
.vector-4{
    transform: rotate(170deg);
}
.vector-5{
    transform: rotate(216deg);
    top: -31px;
}
.vector-6{
    transform: rotate(263deg);
    top: -28px;
}
.diagnol{
    height: 3.29px;
    width: 55.98px;
    background: #ADABD6;
}
.dot-1 .diagnol{
    transform: rotate(317deg) translate(13px, -2px);
}
.dot-2 .diagnol{
    transform: rotate(0deg) translate(13px, 12px);
}
.dot-3 .diagnol{
    transform: rotate(45deg) translate(20px, 20px);
}
.dot-4 .diagnol{
    transform: rotate(137deg) translate(45px, 2px);
}
.dot-5 .diagnol{
    transform: rotate(180deg) translate(45px, -11px);
}
.dot-6 .diagnol{
    transform: rotate(225deg) translate(40px, -19px);
}
.dot-1 .hexagon{
    transform: translate(35px, -155px);
}
.dot-2 .hexagon{
    transform: translate(65px, -70px);
}
.dot-3 .hexagon{
    transform: translate(25px, 10px);
}
.dot-4 .hexagon{
    transform: translate(-375px, 10px);
}
.dot-5 .hexagon{
    transform: translate(-415px, -73px);
}
.dot-6 .hexagon{
    transform: translate(-385px, -155px);
}
.Name{
    text-align: center;
}
.stepText{
    font-size: 24px;
    font-weight: 400;
    line-height: 28.8px;
    text-align: Center;
    width: fit-content;
    margin: 0;
    position: absolute;
}
.step-1, .step-6{
    top: 209px;
    top: -10%;
    z-index: 2;
}
.step-2, .step-5{
    top: 435px;
    top: 46%;
    z-index: 2;
}
.step-3, .step-4{
    top: 660px;
    top: 102%;
    z-index: 2;
}
.step-1{
    /* right: 410px; */
    left: -23%;
}
.step-2{
    /* right: 332px; */
    left: -43%;
}
.step-3{
    /* right: 443px; */
    left: -15%;
}
.step-6{
    /* left: 405px; */
    right: -22%;
}
.step-5{
    /* left: 329px; */
    right: -42%;
}
.step-4{
    /* left: 437px; */
    right: -14%;
}
.stepName{
    position: absolute;
    font-size: 30px;
    font-weight: 400;
    line-height: 36px;
    text-align: left;
    margin: 0;
}
.stepName-1, .stepName-6{
    top: 219px;
    top: -9%;
}
.stepName-2, .stepName-5{
    top: 444px;
    top: 49%;
}
.stepName-3, .stepName-4{
    top: 670px;
    top: 105%;
}
.stepName-1{
    /* right: 223px; */
    left: -70%;
}
.stepName-2{
    /* right: 150px; */
    left: -88%;
}
.stepName-3{
    /* right: 180px; */
    left: -82%;
}
.stepName-6{
    /* left: 140px; */
    right: -89%;
}
.stepName-5{
    /* left: 78px; */
    right: -105%;
}
.stepName-4{
    /* left: 256px; */
    right: -59%;
}
.teamImage{
    height: 350px;
    border-radius: 16px;
    margin-bottom: 16px;
}
.teamName{
    font-size: 24px;
    font-weight: 500;
    line-height: 34px;
    margin: 0;
}
.designation{
    font-size: 16px;
    font-weight: 400;
    line-height: 23px;
    margin: 0;
    opacity: 60%;
}

/* Career */
.step-div{
    position: relative;
    height: 200px;
    z-index: -1;
}
.spoiler-text{
    position: absolute;
    width: 100vw;
    margin-top: 30px;
}
.panel {
  position: absolute;
  transition: transform 1s;
  transform-origin: 0% 50%;
  border-radius: 5px;
  animation: careerStep 1 15s linear;
  overflow: hidden;
  width: 100vw;
  margin-top: 30px;
}
.hiring{
    font-size: 18px;
    font-weight: 400;
    line-height: 18px;
    margin: 0;
}
.positionName{
    font-size: 28px;
    font-weight: 400;
    line-height: 33.6px;
}
.positionDesc{
    margin: 6px 0 0;
    font-size: 16px;
    font-weight: 400;
    line-height: 22.4px;
}
.applyTable{
    border-radius: 16px;
    background: #111111;
    width: 100%;
    display: flex;
    flex-direction: column;
}
.applyRow{
    display: flex;
    align-items: center;
    padding: 40px;
    border: 1px solid #000;
    width: 100%;
}
.applyCell1{
    flex-direction: column;
    width: 60%;
}
.applyCell2{
    font-size: 18px;
    font-weight: 400;
    line-height: 21.6px;
    text-align: right;
    width: 20%;
}
.applyCell3{
    text-decoration: none;
    color: #FFFFFF;
}
.applyRow:hover .positionName{
    color: transparent;
    background: linear-gradient(90deg, #333399, #FF00CC 25%);
    background-clip: text;
}
.training{
    font-size: 20px;
    font-weight: 400;
    line-height: 20px;
}
img.training:hover{
    border-radius: 50%;
    animation: pulse-animation 2s infinite;
}

/* Career Detail */
.ListName{
    font-size: 28px;
    line-height: 28px;
    font-weight: 400;
    margin: 0 0 20px;
}
.CareerList{
    padding-inline-start: 15px;
    margin: 0;
}
.CareerList li{
    font-size: 20px;
    line-height: 28px;
}
.career-card{
    background: #F5F5F5;
    padding: 30px 20px;
    font-size: 28px;
    line-height: 28px;
    margin-left: 40px;
    height: fit-content;
}
.career-card-title{
    font-size: 18px;
    line-height: 18px;
    font-weight: 500;
    margin-top: 24px;
}
.career-card-data{
    font-size: 18px;
    line-height: 18px;
    font-weight: 400;
}
.career-card-button{
    margin-top: 30px;
    font-family: 'Space Grotesk';
}
.career-card-button:hover{
    background-image: linear-gradient(to right, #333399, #FF00CC), linear-gradient(to right, #F5F5F5, #f5f5f5), linear-gradient(to right, #333399, #FF00CC);
}
#modal{
    position: absolute;
    background: #000b;
    height: 100vh;
    width: 100vw;
    align-items: center;
    justify-content: center;
}
.form{
    background: #F5F5F5;
    padding: 15px;
}
.form div{
    display: flex;
    flex-direction: column;
}
#modal input{
    border: 1px solid #E2E2E2;
    background: #E9E9E9;
    width: 350px;
    padding: 24px 20px 24px 20px;
    border-radius: 16px;
    margin: 10px 0;
}

/* Contact Us */
.contactIcon{
    margin-right: 8px;
}
.contactName{
    font-size: 18px;
    font-weight: 500;
    line-height: 25.2px;
    margin: 0;
}
.contactData{
    font-size: 16px;
    font-weight: 400;
    line-height: 22.4px;
    margin: 5px 0 0;
    color: #000000;
    text-decoration: none;
}
.career-card input, .career-card textarea{
    border: 1px solid #E2E2E2;
    background: #E9E9E9;
    width: 90%;
    padding: 24px 20px 24px 20px;
    border-radius: 16px;
}
.success{
    border: 1px solid #2E7D32;
    background: #C8E6C9;
    color: #2E7D32;
    font-size: 18px;
    font-weight: 400;
    line-height: 21.6px;
    padding: 20px;
}
.warning{
    border: 1px solid #D81B60;
    background: #FCE4EC;
    color: #D81B60;
    font-size: 18px;
    font-weight: 400;
    line-height: 21.6px;
    padding: 20px;
}
/* .career-card input{
    margin-bottom: 20px;
} */
#form {
    display: flex;
    gap: 20px;
    flex-direction: column;
}
.career-card input::placeholder, .career-card textarea::placeholder{
    font-size: 18px;
    font-weight: 400;
    line-height: 21.6px;
    color: #666666;
    opacity: 50%;
}

/*About Us */
.aboutUL{
    display: flex;
    flex-direction: column;
    gap: 20px;
    font-family: Space Grotesk;
    font-size: 16px;
    font-weight: 400;
    line-height: 22.4px;
}
.VMName{
    font-size: 36px;
    font-weight: 400;
    line-height: 43.2px;
    margin: 0 0 10px;
}
.card-dark:hover .VMName{
    color: transparent;
    background: linear-gradient(90deg, #333399, #FF00CC 40%);
    background-clip: text;
}
.VMDetails{
    line-height: 22.4px;
    opacity: 80%;
}
.testimonials{
    font-family: Space Grotesk;
    font-size: 18px;
    font-weight: 400;
    line-height: 18px;
    text-align: center;
    margin: 0 0 10px;
}
.testimonial-heading{
    margin: 0;
    text-align: center;
}
.slider-img{
    height: 320px;
    width: 320px;
    border-radius: 8px;
}
.testimonial{
    font-size: 24px;
    font-weight: 300;
    line-height: 33.6px;
    color: #000000CC;
}
.CEOName{
    font-size: 32px;
    font-weight: 500;
    line-height: 44.8px;
    color: #000000CC;
}
.CEOPost{
    font-size: 18px;
    font-weight: 400;
    line-height: 25.2px;
    color: #000000CC;
    opacity: 60%;
}

/* Animation */
@keyframes animate { 

    0% { 
        content: "Web Development"; 
    } 

    100% { 
        content: "Web Design";
    } 
}
@keyframes careerStep { 

    0% { 
        width: 0;
    } 

    100% { 
        width: 100vw;
    } 
}
@keyframes pulse-animation {
    0% {
      box-shadow: 0 0 0 0px #33339933;
    }
    25% {
      box-shadow: 0 0 0 20px #33339933;
    }
    50% {
        box-shadow: 0 0 0 20px #ff00cc33;
    }
    100% {
        box-shadow: 0 0 0 0px #ff00cc33;
    }
  }
  @keyframes HWW { 
  
      0% { 
          height: 0;
      } 
  
      100% { 
          height: 100%;
      } 
  }
  
  
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {

    /*Common*/
    .heading{
        font-size: 24px;
        line-height: 24px;
        font-weight: 700;
        margin: 0;
        margin-bottom: 8px;
    }
    .sub-heading{
        color: #303030;
        font-size: 16px;
        line-height: 20.8px;
    }
    .card, .card-dark{
        padding: 20px;
    }
    .card-contain{
        margin-top: 24px;
    }
    button {
        padding: 16px 28px 16px 28px;
        font-size: 16px;
    }
    .tag{
        margin: 40px 20px 10px;
    }
    .page-title{
        margin: 0 20px;
        font-size: 31px;
        line-height: 31px;
    }
    .page-desc{
        margin: 10px 20px 0;
        font-size: 16px;
        line-height: 20px;
    }

    /* Header */
    .logo{
        height: 58.32px;
    }
    .logo-sm{
        height: 40px;
    }
    #menu{
        position: absolute;
        height: 100vh;
        width: 100vw;
        padding-left: 40px;
        background: #000d;
        top: 0;
        right: 0;
        display: none;
        flex-direction: column;
        justify-content: space-evenly;
    }
    #closeBtn{
        color: white;
        font-size: 32px;
        font-weight: 100;
        padding-right: 20px;
        display: flex;
        justify-content: flex-end;
    }
    .menu-item{
        margin-left: 20px;
        margin-right: 20px;
    }
    #menu .menu-item{
        display: flex;
        font-size: 24px;
        color: #FFFFFF80;
        text-decoration: none;
        padding-bottom: 32px;
    }
    #menu .menu-item.active{
        color: #FFFFFF;
    }
    a.menu-item{
        color: #FFFFFF;
    }
    .menu-button .menu-item{
        padding: 12px 22px 12px 22px !important;
        font-size: 16px;
        color: #FFFFFF !important;
    }
    .menu-button{
        display: flex;
    }

    /* Footer */
    .footer{
        padding: 30px 20px;
        border-bottom: 1px solid #8f8f8f;
    }
    .footer h6{
        font-size: 24px;
        line-height: 24px;
    }
    .footer-text{
        margin: 8px 0 32px;
        font-size: 14px;
        line-height: 19.6px;
    }
    .iconDiv{
        padding: 12px 12px 7px;
        margin-bottom: 32px;
    }
    .iconDiv.flex{
        padding: 12px;
        margin: 0;
    }
    .footer-contact{
        font-size: 14px;
        line-height: 19.6px;
    }
    .footer-contact>*{
        margin: 0;
    }
    .copyrightTab{
        padding: 8px 0 16px;
        font-size: 12px;
        line-height: 16.8px;
    }

    /* Home Page */
    .section1-desc{
        color: #FFFFFF;
        width: auto;
        font-size: 16px;
        font-weight: 400;
        line-height: 25.6px;        
        text-align: center;
        margin-left: 20px;
        margin-right: 20px;
    }
    .title{
        font-size: 32px;
        line-height: 38.4px;
    }
    .serviceName{
        font-size: 22px;
        line-height: 26.4px;
        margin: 0 0 10px 0
    }
    .serviceDescription{
        font-size: 14px;
        line-height: 19.6px;
        margin: 0;
    }
    .bg-section1-home{
        height: calc(100vh - 70px);
    }
    .hide-cards{
        display: none;
    }
    #unhide-cards{
        color: transparent;
        background: linear-gradient(90deg, #333399, #FF00CC 40%);
        background-clip: text;
    }
    .aboutusText{
        font-size: 14px;
        line-height: 14px;
        font-weight: 400;
    }
    .aboutList li{
        font-size: 14px;
        line-height: 19.6px;
        opacity: 80%;
    }
    .HWWImgDiv{
        position: absolute;
        top: 10px;
        left: -4px;
        overflow: hidden;
    }
    .HWWImgDivAnime{
        animation: HWW 1 15s linear; 
    }
    .teamName{
        font-size: 22px;
        line-height: 30.8px;
    }
    .designation{
        line-height: 22.4px;
    }
    
    /*About Us */
    .aboutUL{
        gap: 7px;
        font-size: 14px;
        line-height: 16.8px;
        margin: 0;
    }
    .aboutImg{
        width: 100%;
    }
    .VMName{
        font-size: 28px;
        line-height: 28px;
    }
    .VMDetails{
        font-size: 14px;
        line-height: 17.8px;
        margin: 0;
    }
    .testimonial{
        font-size: 16px;
        line-height: 22px;
    }
    .CEOName{
        font-size: 22px;
        line-height: 34.8px;
    }
    .CEOPost{
        font-size: 16px;
        line-height: 20px;
    }

    /* Career */
    .applyRow{
        padding: 20px;
        flex-direction: column;
        width: auto;
    }
    .applyCell1, .applyCell2{
        width: auto;
    }
    .applyCell2{
        font-size: 16px;
    }
    .positionName{
        font-size: 20px;
        line-height: 22px;
    }
    .positionDesc{
        font-size: 14px;
        line-height: 17.8px;
    }
    .posRow{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        margin-top: 16px;
    }

    /* Career Detail */
    .career-card-title{
        font-size: 20px;
        line-height: 24px;
    }
    .career-card-data{
        line-height: 24px;
    }
    .career-card-button{
        font-size: 19px;
    }
    .ListName{
        margin: 0 0 15px;
        font-size: 20px;
        line-height: 20px;
    }
    .CareerList li {
        font-size: 16px;
        line-height: 20px;
    }
    #modal input {
        border: 1px solid #E2E2E2;
        background: #E9E9E9;
        width: calc(100% - 50px);
        padding: 24px 20px 24px 20px;
        border-radius: 16px;
        margin: 10px 0;
    }

    /* Contact Us */
    .career-card{
        margin: 0;
    }
  }
  
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) {
    .example {background: green;}
  }
  
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) {
    .example {background: blue;}
  } 
  
  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
    .example {background: orange;}
  } 
  
  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 1200px) {
    .example {background: pink;}
  }
