@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300..700&display=swap');
body {
  margin: 0;
  font-family: "Space Grotesk", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family:  "Space Grotesk", 'Courier New',
    monospace;
}
